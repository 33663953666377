<template>
  <div>
    <NoScript>
      <div><img src="https://mc.yandex.ru/watch/64709935" style="position:absolute; left:-9999px;" alt="" /></div>
      <div><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-M2KJX7JB" height="0" width="0" style="display:none;visibility:hidden"></iframe></div>
    </NoScript>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup>
const runtimeConfig = useRuntimeConfig()

useHead({
  script: [
    { src: `https://api-maps.yandex.ru/2.1/?apikey=${runtimeConfig.public.ymapsApikey}&lang=ru_RU`, defer: true, async: false },
    {
      innerHTML: `
      (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      m[i].l=1*new Date();
      for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
      k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

      ym(64709935, "init", {
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:true
      });

      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-M2KJX7JB');

      `
    }
  ]
})

</script>
